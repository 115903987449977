import {
  addOrganizationPolicyRequest,
  deleteAxiosOrganization,
  getAxiosOrganizationById,
  getAxiosOrganizations,
  postAxiosCreateOrganization,
  postAxiosUploadFile,
  putAxiosActivateUser,
  putAxiosOrganization,
} from "@API/Organizations/endpoints";
import {
  IAxiosOrganization,
  IGetAxiosOrganizations,
  IPostAxiosOrganization,
  NewOrganizationPayload,
} from "@API/Organizations/types";
import i18n from "@i18n";
import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {
  addOrganizationPolicy,
  addOrganizationPolicyFail,
  addOrganizationPolicySuccess,
  createOrganizationFail,
  createOrganizationRequest,
  createOrganizationSuccess,
  deleteOrganizationFail,
  deleteOrganizationRequest,
  deleteOrganizationSuccess,
  getOrganizationByIdFail,
  getOrganizationByIdRequest,
  getOrganizationByIdSuccess,
  getOrganizationPolicyFail,
  getOrganizationPolicyRequest,
  getOrganizationPolicySuccess,
  organizationsListFail,
  organizationsListRequest,
  organizationsListSuccess,
  resetCreateOrganizationModal,
  systemUpdateOrganizationFail,
  systemUpdateOrganizationRequest,
  systemUpdateOrganizationSuccess,
  toggleUserActiveStatus,
  toggleUserActiveStatusFail,
  toggleUserActiveStatusSuccess,
  updateOrganizationFail,
  updateOrganizationRequest,
  updateOrganizationSuccess,
  updateSelectedOrganizationId,
  uploadImageOrganizationRequest,
  uploadImageOrganizationSuccess,
} from "./actions";
import {IOrganization, OrganizationId, UploadImageOrganizationPayload,} from "./types";
import {IGetAxiosAppUser} from "@API/AppUser/types";
import {getAxiosAppUser} from "@API/AppUser/endpoints";
import {appUserDataSuccess} from "@Store/AppUser/actions";
import {getOrganizationPolicyEndpoint} from "@API/Policies/endpoints";
import {showSnackbar} from "@Store/toast/actions";

function* onOrganizationsListRequest() {
  try {
    const response: IGetAxiosOrganizations = yield call(getAxiosOrganizations);

    if (response.error) throw response.error;

    yield new Promise((resolve) => setTimeout(resolve, 500));
    yield put(organizationsListSuccess(response.data));

    if (response.data.length > 0) {
      yield put(updateSelectedOrganizationId(response.data[0].orgId));
    }
  } catch (e) {
    console.error(e);
    yield put(organizationsListFail(e as Error));
  }
}

function* onOrganizationListRequest(action: { payload: string }): Generator {
  try {
    const response = (yield call(
      getAxiosOrganizationById,
      action.payload
    )) as AxiosResponse<IAxiosOrganization>;
    yield put(getOrganizationByIdSuccess(response.data));
  } catch (e) {
    if (e instanceof Error) {
      yield put(getOrganizationByIdFail(e));
    } else {
      yield put(
        getOrganizationByIdFail(new Error("An unexpected error occurred"))
      );
    }
  }
}

function* onUpdateOrganizationRequest({
  payload,
}: PayloadAction<IOrganization>) {
  try {
    const response: IAxiosOrganization = yield call(
      putAxiosOrganization,
      payload
    );
    if (response.error) throw response.error;

    yield new Promise((resolve) => setTimeout(resolve, 500));
    yield put(updateOrganizationSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(updateOrganizationFail(e as Error));
  }
}

function* onSystemUpdateOrganizationRequest({
  payload,
}: PayloadAction<IOrganization>) {
  try {
    const response: IAxiosOrganization = yield call(
      putAxiosOrganization,
      payload
    );
    if (response.error) throw response.error;

    yield new Promise((resolve) => setTimeout(resolve, 500));
    yield put(systemUpdateOrganizationSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(systemUpdateOrganizationFail(e as Error));
  }
}

function* onUpdateSelectedOrganizationId({
  payload,
}: PayloadAction<OrganizationId>) {
  // yield put(usersListRequest(payload));
}

// system user requests
function* onCreateOrganizationRequest({
  payload,
}: PayloadAction<NewOrganizationPayload>) {
  try {
    const response: IPostAxiosOrganization = yield call(
      postAxiosCreateOrganization,
      payload
    );

    if (response.error) throw response.error;

    yield put(resetCreateOrganizationModal());
    yield put(createOrganizationSuccess(response.data));

    yield put(
      showSnackbar(
        i18n.t("organization.modals.addOrganization.successMessage"),
        "success"
      )
    );
  } catch (e) {
    yield put(createOrganizationFail(e as Error));

    yield put(
      showSnackbar(
        i18n.t("organization.modals.addOrganization.failMessage"),
        "error",
        e
      )
    );
  }
}

function* onDeleteOrganizationRequest({
  payload,
}: PayloadAction<OrganizationId>) {
  try {
    const response: { error?: Error } = yield call(
      // @ts-ignore
      deleteAxiosOrganization,
      payload
    );
    if (response.error) throw response.error;

    yield new Promise((resolve) => setTimeout(resolve, 500));
    yield put(deleteOrganizationSuccess(payload));
  } catch (e) {
    console.error(e);
    yield put(deleteOrganizationFail(e as Error));
  }
}

function* onUploadImageOrganizationRequest({
  payload,
}: PayloadAction<UploadImageOrganizationPayload>) {
  try {
    const { org_id, file_type, file } = payload;

    yield call(postAxiosUploadFile, org_id, file_type, file);

    yield put(uploadImageOrganizationSuccess());

    const appUser: IGetAxiosAppUser = yield call(getAxiosAppUser);
    yield put(appUserDataSuccess(appUser.data));
    yield put(getOrganizationByIdRequest(org_id));
    // notification.success({
    //   message: i18n.t("organization.uploadImage.successMessage"),
    // });

    yield put(
      showSnackbar(i18n.t("organization.uploadImage.successMessage"), "success")
    );
  } catch (e) {
    // If there's an error, dispatch a failure action
    const errorMessage =
      isAxiosError(e) && e.response
        ? e.response.data.message
        : (e as AxiosError).message;
  }
}

function* onAddOrganizationPolicyRequest({ payload }: PayloadAction<any>) {
  try {
    yield call(addOrganizationPolicyRequest, payload);

    yield put(addOrganizationPolicySuccess());
    // notification.success({
    //   message: i18n.t("organization.modals.addPolicy.successMessage"),
    // });

    yield put(
      showSnackbar(
        i18n.t("organization.modals.addPolicy.successMessage"),
        "success"
      )
    );

    yield put(
      getOrganizationPolicyRequest({
        orgId: payload.org_id || "",
        type: payload.type === "PRIVACY" ? "privacy" : "gtac",
      })
    );
  } catch (e) {
    const errorMessage =
      isAxiosError(e) && e.response
        ? e.response.data.message
        : (e as AxiosError).message;

    yield put(addOrganizationPolicyFail());
    // notification.error({
    //   message: i18n.t("organization.modals.addPolicy.failMessage"),
    //   description: errorMessage,
    // });
    yield put(
      showSnackbar(i18n.t("organization.modals.addPolicy.failMessage"), "error", e)
    );
  }
}

function* onToggleUserActiveStatusRequest(
  action: PayloadAction<{ org_id: string; user_id: string; isActive: boolean }>
) {
  try {
    yield call(
      putAxiosActivateUser,
      action.payload.org_id,
      action.payload.user_id,
      action.payload.isActive
    );
    yield put(
      toggleUserActiveStatusSuccess({
        org_id: action.payload.org_id,
        user_id: action.payload.user_id,
        isActive: action.payload.isActive,
      })
    );
    // notification.success({
    //   message: "User status updated successfully"
    // });

    yield put(showSnackbar("User status updated successfully", "success"));
  } catch (e) {
    yield put(toggleUserActiveStatusFail(e as Error));
    yield put(showSnackbar("Failed to update user status", "error", e));
  }
}

function* onGetOrganizationPolicyRequest(action: any) {
  try {
    const { orgId, type } = action.payload;
    const response: AxiosResponse = yield call(
      getOrganizationPolicyEndpoint,
      orgId,
      type
    );
    yield put(getOrganizationPolicySuccess({ orgId, type, ...response.data }));
  } catch (error) {
    yield put(
      getOrganizationPolicyFail({
        orgId: action.payload.orgId,
        type: action.payload.type,
        error,
      })
    );
  }
}

export default function* () {
  yield takeLatest(organizationsListRequest, onOrganizationsListRequest);
  yield takeLatest(getOrganizationByIdRequest, onOrganizationListRequest);
  yield takeLatest(
    updateSelectedOrganizationId,
    onUpdateSelectedOrganizationId
  );
  yield takeLatest(updateOrganizationRequest, onUpdateOrganizationRequest);
  yield takeLatest(createOrganizationRequest, onCreateOrganizationRequest);
  yield takeLatest(deleteOrganizationRequest, onDeleteOrganizationRequest);
  yield takeLatest(
    uploadImageOrganizationRequest,
    onUploadImageOrganizationRequest
  );
  yield takeLatest(addOrganizationPolicy, onAddOrganizationPolicyRequest);
  yield takeLatest(toggleUserActiveStatus, onToggleUserActiveStatusRequest);
  yield takeLatest(
    systemUpdateOrganizationRequest,
    onSystemUpdateOrganizationRequest
  );
  yield takeLatest(
    getOrganizationPolicyRequest.type,
    onGetOrganizationPolicyRequest
  );
}
