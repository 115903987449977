import {call, put, takeLatest} from "redux-saga/effects";
import {
  activateTeamFail,
  activateTeamRequest,
  activateTeamSuccess,
  bulkImportTeamFail,
  bulkImportTeamRequest,
  bulkImportTeamSuccess,
  createTeamFail,
  createTeamRequest,
  createTeamSuccess,
  deactivateTeamFail,
  deactivateTeamRequest,
  deactivateTeamSuccess,
  singleTeamFail,
  singleTeamRequest,
  singleTeamSuccess,
  teamsListFail,
  teamsListRequest,
  teamsListSuccess,
  updateTeamRequest,
  updateTeamSuccess,
} from "./actions";
import {
  activateTeamRequest as apiActivateTeam,
  deactivateTeamRequest as apiDeactivateTeam,
  getAxiosSingleTeamRequest,
  getAxiosTeamsRequest,
  patchAxiosUpdateTeamRequest,
  postAxiosCreateTeamRequest,
  postBulkImportTeamRequest
} from "@API/Teams/endpoints";
import {ICreateTeamPayload} from "@API/Teams/types";
import {showSnackbar} from "@Store/toast/actions";
import i18n from "i18next";

function* onTeamsListRequest(action: { payload: string }): Generator {
  try {
    const response = yield call(getAxiosTeamsRequest, action.payload);
    // @ts-ignore
    yield put(teamsListSuccess(response.data));
  } catch (error) {
    yield put(teamsListFail(error as Error));
  }
}

function* onCreateTeamRequest(action: {
  payload: { orgId: string; payload: ICreateTeamPayload };
}): Generator {
  try {
    const response = yield call(
      postAxiosCreateTeamRequest,
      action.payload.orgId,
      action.payload.payload
    );

    // @ts-ignore
    yield put(createTeamSuccess(response.data));
    yield put(teamsListRequest(action.payload.orgId));
    yield put(showSnackbar("Team created successfully", "success"));
  } catch (error) {
    yield put(createTeamFail(error as Error));

    yield put(showSnackbar("Failed to create team", "error", error));
  }
}

function* onBulkImportTeamRequest(action: {
  payload: { orgId: string; file: File };
}) {
  try {
    const {orgId, file} = action.payload;
    yield call(postBulkImportTeamRequest, orgId, file);
    yield put(bulkImportTeamSuccess());

    yield put(showSnackbar(i18n.t("bulkUpload.alert.success"), "success"));

    yield put(teamsListRequest(orgId));
  } catch (e) {
    console.error(e);
    yield put(bulkImportTeamFail());
    //
    yield put(showSnackbar(i18n.t("bulkUpload.alert.error"), "error", e));
  }
}

function* onUpdateTeamRequest(action: {
  payload: { orgId: string; payload: ICreateTeamPayload; teamId: string };
}): Generator {
  try {
    const response = yield call(
      patchAxiosUpdateTeamRequest,
      action.payload.teamId,
      action.payload.orgId,
      action.payload.payload
    );
    // @ts-ignore
    yield put(updateTeamSuccess(response.data));
    yield put(teamsListRequest(action.payload.orgId));
    yield put(showSnackbar("Team updated successfully", "success"));
  } catch (error) {
    yield put(createTeamFail(error as Error));

    yield put(showSnackbar("Failed to update team", "error", error));
  }
}

function* onActivateTeamRequest(action: {
  payload: { orgId: string; teamCode: string };
}): Generator {
  try {
    yield call(apiActivateTeam, action.payload.orgId, action.payload.teamCode);
    yield put(activateTeamSuccess({teamCode: action.payload.teamCode}));
    yield put(showSnackbar("Team activated successfully", "success"));
  } catch (error) {
    yield put(activateTeamFail(error as Error));
    yield put(showSnackbar("Failed to activate team", "error", error));
  }
}

function* onDeactivateTeamRequest(action: {
  payload: { orgId: string; teamCode: string };
}): Generator {
  try {
    yield call(
      apiDeactivateTeam,
      action.payload.orgId,
      action.payload.teamCode
    );
    yield put(deactivateTeamSuccess({teamCode: action.payload.teamCode}));
    yield put(showSnackbar("Team deactivated successfully", "success"));
  } catch (error) {
    yield put(deactivateTeamFail(error as Error));
    yield put(showSnackbar("Failed to deactivate team", "error", error));
  }
}

function* onSingleTeamRequest(action: {
  payload: { orgId: string; id: string };
}): Generator {
  try {
    const response = yield call(
      getAxiosSingleTeamRequest,
      action.payload.orgId,
      action.payload.id
    );

    // @ts-ignore
    yield put(singleTeamSuccess(response.data));
  } catch (error) {
    yield put(singleTeamFail(error as Error));
  }
}

export default function* teamsSagas() {
  yield takeLatest(createTeamRequest, onCreateTeamRequest);
  yield takeLatest(updateTeamRequest, onUpdateTeamRequest);
  yield takeLatest(teamsListRequest, onTeamsListRequest);
  yield takeLatest(singleTeamRequest, onSingleTeamRequest);
  yield takeLatest(activateTeamRequest, onActivateTeamRequest);
  yield takeLatest(deactivateTeamRequest, onDeactivateTeamRequest);
  yield takeLatest(bulkImportTeamRequest, onBulkImportTeamRequest);
}
