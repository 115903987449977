import {AxiosError} from "axios";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export function showSnackbar(message: string, severity: string, exception?: any) {
  if (exception instanceof AxiosError && exception.response) {
    return getSnackbar(exception.response.data.message, severity);
  } else {
    return getSnackbar(message, severity);
  }
}

export const getSnackbar = (message: any, severity: any) => ({
  type: SHOW_SNACKBAR,
  payload: {message, severity},
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});
